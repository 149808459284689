import React, {useState, useEffect, useRef} from 'react';
import {adminApi, camelApi} from "./aaxios";

import './App.css';


// 세션 아이디 한 번만 실행
let session_id;

const GetSessionId = async () => {
    if (session_id) {
        return;
    }

    try {
        const response = await adminApi.get('/session/get_id');
        session_id = response.data.result;
        console.log('response1 : ', response);
        console.log('session_id : ', session_id);
    } catch (error) {
        console.log('false!!');
        // throw error;
    }
};

// 최초 1회 실행
GetSessionId();

const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [text, setText] = useState('');
    const [displayedText, setDisplayedText] = useState('안녕하세요. 뷰성형외과 입니다. 무엇이 궁금하신가요');
    const [isLoading, setIsLoading] = useState(false); // 답변 대기중 스피너 출력

    // suggestion chip에 들어갈 faq 관리
    const [rawFaqs, setRawFaqs] = useState([]);
    const [faqs, setFaqs] = useState([]);

    // Admin page data handlings
    const [answerNum, setAnswerNum] = useState(1);

    // 사용자 연락처(테스트)
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');

    const [isPopupVisible, setIsPopupVisible] = useState(true);


    // 사용자 연락처
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    // 사용자 정보 백엔드 전송
    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     console.log("사용자 정보");
    //     console.log("session_id : ", session_id);
    //     console.log("name : ", name);
    //     console.log("phoneNumber : ", contact);

    //     const data = {
    //     "sessionId": session_id,
    //     "name": name,
    //     "phoneNumber": contact
    //     };

    //     try {
    //     const response = await axios.put('https://viewbeauty-admin.o2o.kr/api/v1/customer', data, {
    //         headers: {
    //         'Content-Type': 'application/json'
    //         }
    //     });

    //     console.log('고객정보 입력 response >> ', response);

    //     if (response.status === 200 || response.status === 201) {
    //         alert('데이터가 성공적으로 전송되었습니다.');
    //         handleCloseModal();
    //     } else {
    //         alert('데이터 전송에 실패했습니다.');
    //     }
    //     } catch (error) {
    //     console.error('Error sending data:', error);
    //     alert('데이터 전송 중 오류가 발생했습니다.');
    //     }
    // };


    // Admin 사용자 정보 백엔드 전송
    const handleSubmit = async (event) => {
        event.preventDefault();  // 이벤트 기본 동작을 막아 페이지 새로고침을 방지

        try {
            const response = await adminApi('/customer', {
                method: 'POST',
                mode: 'no-cors',  // "no-cors"는 헤더 설정을 제한하므로 "cors"로 설정
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "sessionId": session_id,
                    "name": name,
                    "phoneNumber": contact
                }),
            });

            console.log('check point 01');

            // const data = await response.json();
            const data = await response;
            console.log('고객정보 입력 response : ', typeof data, data);

            if (response.status === 200 || response.status === 201) {
                alert('데이터가 성공적으로 전송되었습니다.');
                handleCloseModal();
            } else {
                alert('고객정보 전송 완료');
                handleCloseModal();
            }
        } catch (error) {
            alert('데이터 전송 에러');
            console.error('Error:', error);
        }
    };


    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            handleCloseModal();
        }
    };

    useEffect(() => {
        if (modalOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalOpen]);

    // 스크롤 하단 고정
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
    };
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // 모바일 100vh issue 해결을 위한 스크립트
    const setScreenSize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useEffect(() => {
        // 컴포넌트가 마운트될 때 초기화
        setScreenSize();

        // 창 크기가 변경될 때마다 호출
        const handleResize = () => setScreenSize();
        window.addEventListener('resize', handleResize);

        // 컴포넌트가 언마운트될 때 이벤트 리스너 정리
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // 빈 배열을 전달하여 컴포넌트가 처음 마운트될 때만 실행

    // 메세지
    const sendMessage = (messageToSend = text) => {
        console.log("answerNum Checked : ", answerNum);

        // 입력 요소의 포커스 해제
        document.activeElement.blur();

        // 입력된 텍스트 또는 함수에 전달된 메시지를 사용
        const messageContent = messageToSend.trim();
        if (!messageContent) return;

        setMessages(prevMessages => [...prevMessages, {text: messageContent, sender: 'user'}]);
        setText('');
        setIsLoading(true); // 로딩 시작

        // 챗봇 응답 로직을 여기에 추가
        const answerInfo = {
            'user_query': messageContent,
        };
        console.log('user_query', messageContent);

        camelApi.post('/run_view', answerInfo, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                console.log('Response:', response);
                const answer = response.data["output"]

                setMessages(prevMessages => [...prevMessages, {text: answer, sender: 'bot'}]);

                // updateChipsWrapperContent(rawFaqs); // suggestion chip 변경
                updateChipsWrapperContent(); // suggestion chip 변경

                console.log('answer_num >> ', answerNum);

                postAnswer(session_id, answerNum, messageContent, answer);

                setAnswerNum(prevAnswerNum => prevAnswerNum + 1);
                console.log('answer_num +1 >> ', answerNum);

                setIsLoading(false); // 로딩 종료

            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false); // 로딩 종료
            });
    };

    // Admin 서버에 데이터 전송
    const postAnswer = async (sessionId, answerNumber, question, answer) => {
        try {
            const response = await adminApi('/answer/save', {
                method: 'POST',
                mode: "no-cors",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'sessionId': sessionId,
                    'answerNum': answerNumber,
                    'question': question,
                    'answer': answer,
                }),
            });

            console.log('check point 01');
            console.log('answerNum_to_send : ', typeof answerNumber, answerNumber);
            console.log('sessionId : ', typeof sessionId, sessionId);

            const data = await response;
            console.log('db입력후 response : ', typeof data, data);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // Admin 버튼 클릭 이벤트 핸들러
    const handleAdminClick = () => {
        // 이건 또 뭐야..
        window.location.href = process.env.REACT_APP_ADMIN_HOME;
    };

    // 라운드 칩을 유저 데이터에서 받아와서 마운트
    const fetchFAQs = async () => {
        try {
            const response = await adminApi.get('/faq');
            // console.log('faq response >>', response.data);
            const faqArray = response.data.map(item => item.faq); // 객체에서 faq만 추출하여 배열로 구성
            // API로부터 받은 faq가 null이 아니면 해당 값을 사용, null이면 빈 배열 사용
            const faqsData = response.data !== null ? faqArray : [];
            // console.log('user faq list :', faqsData) // faq debug
            // faq가 3개 이상일 경우 무조건 처음에는 3개만 보여주도록 제한함
            if (faqsData.length > 0) {
                setRawFaqs(faqsData);
                let randomFaqs = faqsData.sort(() => Math.random() - 0.5).slice(0, 4);
                setFaqs(randomFaqs);
            } else {
                setFaqs([]);
            }
        } catch (error) {
            console.error('FAQ 데이터 로딩 에러:', error);
            setFaqs([]); // 실패 시, faqs는 빈 배열로 유지
        }
    };

    useEffect(() => {
        fetchFAQs();
    }, []);

    // suggestion chip 내용 바꾸기 : id가 'chips-wrapper'인 요소의 내용을 새로운 데이터로 변환하는 함수
    const updateChipsWrapperContent = (rawFaqs) => {
        console.log('updateChipsWrapperContent', rawFaqs);
        if (rawFaqs.length > 0) {
            let randomFaqs = rawFaqs.sort(() => Math.random() - 0.5).slice(0, 4);
            setFaqs(randomFaqs);
        } else {
            setFaqs([]);
        }
    }

    // 라운드 칩 클릭 시 메시지 전송
    const handleChipClick = (message) => {
        updateChipsWrapperContent(rawFaqs);
        sendMessage(message);
    };

    const getCurrentTime = () => {
        const currentTime = new Date();
        const hours = currentTime.getHours();
        const minutes = currentTime.getMinutes();

        const formattedHours = hours > 12 ? hours - 12 : hours;
        const period = hours >= 12 ? '오후' : '오전';
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${period} ${formattedHours}:${formattedMinutes}`;
    };

    return (
        <div className="App">

            {isPopupVisible && (
                <div className="popup-background">
                    <div className="popup">
                        <p>문의하기 버튼을 통해 이름과 연락처를 남겨주시면 문의하신 내용에 대한 자세한 답변을 드리겠습니다.</p>
                        <button className='modal-submit' onClick={() => setIsPopupVisible(false)}>확인</button>
                    </div>
                </div>
            )}

            <div className={`chat-container ${isLoading ? 'blurred' : ''}`}>
                {modalOpen && <div className="blurred-input-background"/>}
                <div className='wrap'>
                    <div className='container'>
                        {isLoading && (
                            <div className="loading-overlay">
                                <img src="img/spinner_01.gif" alt="로딩중" className="loading-spinner"/>
                            </div>
                        )}
                        <div className='msger'>
                            <header className='msger-header'>
                                <div className='msger-header-title'>
                                    <div className='header-logo-frame'>
                                        <img src='img/viewbeauty_01.svg' alt="Logo"></img>
                                    </div>
                                </div>
                                <button className="rounded-chip-admin" onClick={handleOpenModal}>문의하기</button>
                            </header>
                            <main className='msger-chat msger-chat-frame'>
                                {modalOpen && (
                                    <div className="modal" ref={modalRef}>
                                        <form onSubmit={handleSubmit}>
                                            <div className='modal-header'>
                                                <p className='modal-title'>문의하기</p>
                                            </div>
                                            <div className='modal-header'>
                                                <p className='modal-subtitle'>이름과 연락처를 남겨주시면 문의하신 내용에 대한 자세한 답변을
                                                    드리겠습니다.</p>
                                            </div>
                                            <div>
                                                <p className='modal-input-text'>이름</p>
                                                <input className='modal-input' type="text" value={name}
                                                       onChange={(e) => setName(e.target.value)}
                                                       placeholder='이름을 입력해주세요'/>
                                            </div>
                                            <div>
                                                <p className='modal-input-text'>연락처</p>
                                                <input className='modal-input' type="text" inputmode="tel"
                                                       pattern="[0-9]{11}" maxlength="11" value={contact}
                                                       onChange={(e) => setContact(e.target.value)}
                                                       placeholder="'-' 없이 입력"/>
                                            </div>
                                            <button type="submit" className='modal-submit'>완료</button>
                                        </form>
                                    </div>
                                )}
                                <section className='msger-chat-prompt'>
                                    <div className='scroll-prompt'>
                                        <div className='greeting'>{displayedText}</div>
                                        {messages.map((message, index) => (
                                            <div
                                                className={`msg-bubble ${message.sender === 'user' ? 'msg-right' : 'msg-left'}`}
                                                key={index}>
                                                <div className='user-say-text'>{message.text}</div>
                                                <div className="msg-time">{getCurrentTime()}</div>
                                            </div>
                                        ))}
                                        <div ref={messagesEndRef}/>
                                        {isLoading && <div className="loading-indicator"></div>}
                                    </div>
                                </section>
                                <form className='msger-form' onSubmit={(e) => {
                                    e.preventDefault();
                                    sendMessage();
                                }}>
                                    <div className='chips-wrapper' id='chips-wrapper'>
                                        {faqs.map((faq, index) => (
                                            <div key={index} className="rounded-chip"
                                                 onClick={() => handleChipClick(faq)}>
                                                {faq}
                                            </div>
                                        ))}
                                    </div>
                                    <div className='msger-inputarea'>
                                        <img style={{float: 'left', paddingTop: '12px', marginRight: '10px'}}
                                             src="img/home.svg" alt="home"
                                             onClick={() => window.location.reload()}></img>
                                        <input
                                            className="msger-input"
                                            id="msger-input"
                                            type="text"
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                            placeholder='무엇이든 물어보세요'
                                        />
                                        <button className="send-btn" type="submit">
                                            <img style={{width: '35px', paddingTop: '0px'}} src="img/send.svg"
                                                 alt="send"></img>
                                        </button>
                                    </div>
                                </form>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chatbot;
